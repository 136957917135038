import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import {
  container,
  eventDate,
  eventTitle,
  eventDesc,
  siteTitle,
  menuItem,
  images
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";

// markup
const ItineraryPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <div className={container}>
      <div>
        {isTabletOrMobile && MobileContent()}
        {/* { MobileContent() } */}
      </div>
      <Header></Header>
      <p className={eventDate}>Sunday, May 25, 2025</p>
      <p className={eventTitle}>Mehendi & Sangeet</p>
      <p className={eventDesc}>8:00 pm - 12:00 am</p>
      <p className={eventDesc}>Vincci Ever Eden Beach Resort</p>
      <p className={eventDesc}>47th km Athens - Sounion Av., Anavissos, 190 13, Greece</p>
      <p className={eventDesc}>Dinner will be served.</p>
      <hr />
      <p className={eventDate}>Monday, May 26, 2025</p>
      <p className={eventTitle}>Mangalasnanam</p>
      <p className={eventDesc}>10:00 am - 12:00 pm</p>
      <p className={eventDesc}>Vincci Ever Eden Beach Resort</p>
      <p className={eventDesc}>47th km Athens - Sounion Av., Anavissos, 190 13, Greece</p>
      <br />
      <p className={eventTitle}>Wedding Ceremony</p>
      <p className={eventDesc}> 5:00 pm - 11:00 pm</p>
      <p className={eventDesc}>Pyrgos Melissourgou</p>
      <p className={eventDesc}>42, Leof. Anavissou 5, km, Greece</p>
      <p className={eventDesc}>Dinner will be served.</p>
      <hr />
      <p className={eventDate}>Tuesday, May 27, 2025</p>
      {/* <p className={eventTitle}>Post-Wedding Brunch</p>
      <p className={eventDesc}>11:00 am - 1:00 pm</p>
      <p className={eventDesc}>Vincci Ever Eden Beach Resort</p>
      <p className={eventDesc}>47th km Athens - Sounion Av., Anavissos, 190 13, Greece</p>
      <p className={eventDesc}>Lunch will be served.</p>
      <br /> */}
      <p className={eventTitle}>Reception</p>
      <p className={eventDesc}> 6:00 pm - 4:00 am</p>
      <p className={eventDesc}>Island Art & Taste - Residence</p>
      <p className={eventDesc}>Leof. Poseidonos 66, Vari 166 72, Greece</p>
      <p className={eventDesc}>Dinner will be served.</p>
      <StaticImage
        alt="vineet"
        src="../images/logo.png"
        style={{ display: "block", maxWidth: "50%", maxHeight: "50%" }}
        className={images}
      />
    </div>
  );
};

const MobileContent = () => {
  return (
    <div>
      <div className={menuItem} id="outer-container">
        <Sidebar 
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </div>
      <p className={siteTitle}>Wedding Itinerary</p>
    </div>
  );
};

export default ItineraryPage;
